import { fetchAuthSession } from 'aws-amplify/auth'
import type { AxiosResponse } from 'axios'
import { parse } from 'qs'

import { StorageKey } from '@/utils/constants/enums'
import * as storage from '@/utils/storage'

import type { ResponseData } from './types'

export function getSavedToken() {
  try {
    return storage.get(StorageKey._CypressToken) || ''
  } catch {
    return ''
  }
}

export const IdempotencyKeyHeaderName = 'Idempotency-Key' as const

export async function getIdToken() {
  const savedToken = getSavedToken()

  if (savedToken) return savedToken

  const qsToken = parse(window.location.search, {
    ignoreQueryPrefix: true,
  })?.idToken

  if (qsToken) return qsToken as string

  const authSession = await fetchAuthSession().catch(() => undefined)
  const idToken = authSession?.tokens?.idToken?.toString()

  return idToken
}

export function getServerFormat(
  obj: Record<string, any> = {},
  keyPrefix = '',
): Record<'path' | 'value', string>[] {
  const entries: Record<'path' | 'value', string>[] = []

  const objectFromEntries = Object.entries(obj ?? {})

  if (objectFromEntries.length === 0) return entries

  for (const [path, value] of objectFromEntries) {
    let currentPath

    if (keyPrefix) {
      // Verify if the path is a numeric array index
      if (!isNaN(Number(path))) {
        currentPath = `${keyPrefix}[${path}]`
      } else {
        currentPath = `${keyPrefix}.${path}`
      }
    } else {
      currentPath = path
    }

    // Since null and undefined are objects, add additional check
    if (typeof value === 'object' && value !== null && value !== undefined) {
      entries.push(...getServerFormat(value, currentPath))
    } else {
      entries.push({
        path: currentPath,
        value,
      })
    }
  }

  return entries
}

/**
 * Utility function to extract data from an Ocho API AxiosResponse,
 * it will return null if no data is found so it's compatible with
 * React Query's hooks.
 * @param response AxiosResponse from Ocho API
 * @returns {T | null}
 */
export function getResponseData<T>(response: AxiosResponse<ResponseData<T>>) {
  return response?.data?.data ?? null
}
